import * as React from "react";
import styled from "styled-components";
import Dot from "../svg/Dot";
import Underline from "../common/Underline";

const services = [
  "ControlExpert übernimmt die Gesamtabwicklung von der Schadenmeldung bis zum Prüfbericht.",
  "Gesamtheitliche Anforderung aller Schäden im Bereich mobiles Schadengut (Handys, Brillen, Hörgeräte, Laptops, Digitalkameras und Spielekonsolen).",
  "Schnelle und transparente Prüfprozesse garantieren eine reibungslose Gesamtabwicklung.",
  "Flexible Reaktion auf Reparaturwünsche des Anspruchstellers als Servicegedanken.",
];

export default function Services() {
  return (
    <ServiceSection id="services">
      <H2>
        <Underline>RUNDUM-KOMPETENZ</Underline> FÜR ELEKTRONIKSCHÄDEN
      </H2>
      <Centered>
        <List>
          {services.map((service, i) => (
            <Service key={i}>
              <StyledDot />
              <span>{service}</span>
            </Service>
          ))}
        </List>
      </Centered>
    </ServiceSection>
  );
}

const ServiceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  padding: 7rem 4rem 7rem 4rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const H2 = styled.h2`
  text-align: center;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  max-width: 700px;
`;

const Service = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 2rem;
  list-style-type: none;
`;

const StyledDot = styled(Dot)`
  color: ${(p) => p.theme.colors.primary};
  height: 1.5rem;
  padding-right: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;
