import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import BackButton from "../components/common/BackButton";
import { Container, H1, P } from "./Imprint";
import useScrollToTop from "../hooks/useScrollToTop";

export default function DataProtection() {
  useScrollToTop();
  const history = useHistory();

  return (
    <Container>
      <BackButton
        onClick={() =>
          history.length > 0 ? history.goBack() : history.push("/")
        }
      >
        &larr; Zurück
      </BackButton>
      <H1>DATENSCHUTZERKLÄRUNG</H1>
      <P>
        Uns ist Ihre Privatsphäre wichtig. Daher achten wir bei der
        Control€xpert GmbH auf die Einhaltung der datenschutzrechtlichen
        Vorgaben. Nachstehend möchten wir Sie darüber informieren, für welche
        Zwecke Ihre Daten erhoben und verwendet werden und wie Sie Ihre Rechte
        wahrnehmen können.
      </P>
      <P>
        Die Datenschutzerklärung können Sie jederzeit über den Link
        „Datenschutz“ aufrufen und ausdrucken.
      </P>
      <P>
        Unsere EDV-Systeme sind durch technische und organisatorische Maßnahmen
        gegen Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte
        Personen, sowie gegen Verlust und Zerstörung gesichert.
      </P>
      <H2>
        Verantwortlich für die Erhebung und Verarbeitung von personenbezogenen
        Daten ist die:
      </H2>
      <P>
        Control€xpert GmbH
        <br />
        Marie-Curie-Str. 3<br />
        40764 Langenfeld
        <br />
        Telefon: +49 2173 84984-460
        <br />
        E-Mail: info(at)controlexpert.com
      </P>
      <H2>
        Wenn Sie eine Frage zum Datenschutz oder zur Datensicherheit haben,
        erreichen Sie unseren Datenschutzbeauftragten:
      </H2>
      <P>
        Herr Lars Wolter
        <br />
        Control€xpert GmbH
        <br />
        Marie-Curie-Str. 3
        <br />
        40764 Langenfeld
        <br />
        E-Mail: datenschutzbeauftragter(at)controlexpert.com
      </P>
      <H2>ZWECK UND RECHTSGRUNDLAGE DER DATENVERARBEITUNG</H2>
      <P>
        Wir speichern Ihre Daten zur Erfüllung der vertraglichen Leistung wie
        Schadenabwicklung, Lieferung, Zahlungsabwicklung und Abrechnung. Der
        Vertrag mit Ihnen ist die Rechtsgrundlage für diese Verarbeitung (Art.6
        Abs.1 Buchstabe b DSGVO). Ihre Daten werden wir im gesetzlich
        erforderlichen Umfang auch zur Erfüllung unserer Archivierungs- und
        Aufbewahrungspflichten nach dem deutschen Handels- und Steuerrecht
        verarbeiten (Art. 6 Abs. 1 Buchstabe c DSGVO). Darüber hinaus verwenden
        wir Ihre Daten zur Direkt-Werbung – also zur Zusendung von Informationen
        über unsere Produkte, Leistungen und Aktionen (gemäß Art. 6 Abs. 1
        Buchstabe f. DSGVO), um Sie als Kunde hierfür zu gewinnen (Information
        gemäß Art. 13 Abs. 1 Buchstabe d DSGVO). Der Verwendung Ihrer Daten zum
        Zweck der Werbung können Sie jederzeit, unter den oben genannten
        Kontaktdaten, widersprechen. Weitere Empfänger dieser Daten im Rahmen
        der Abwicklung des Vertrags über die Bestellung sind
        Logistikdienstleister (nur Adressdaten), Zahlungsdienstleister (nur
        Bezahldaten) oder im Rahmen der Direkt-Werbung auch Dienstleister für
        den Werbemittelversand (nur Adressdaten). Die Dauer der Verarbeitung
        richtet sich nach dem Zweck der Vertragserfüllung sowie der damit
        verbundenen weiteren gesetzlichen Vorgaben zur Aufbewahrung und
        Nachweiserbringung. Zur Direktwerbung verwenden wir die Daten so lange,
        wie Sie nicht widersprochen haben.
      </P>
      <H2>BESUCH DER WEBSITE</H2>
      <P>
        Die Control€xpert GmbH erhebt und speichert automatisch in ihren
        Server-Logfiles Informationen, die Ihr Browser beim Besuch der Website
        an uns übermittelt. Dies sind:
      </P>
      <Ul>
        <Li>Browsertyp/-version </Li>
        <Li>verwendetes Betriebssystem </Li>
        <Li>Referrer-URL (die zuvor besuchte Seite) </Li>
        <Li>Hostname des zugreifenden Rechners (IP-Adresse) </Li>
        <Li>Uhrzeit der Serveranfrage </Li>
      </Ul>
      <P>
        Diese Daten sind für die Control€xpert GmbH nicht bestimmten Personen
        zuzuordnen. Eine Zusammenführung dieser Daten mit anderen Datenquellen
        wird nicht vorgenommen, die Daten werden zudem nach einer statistischen
        Auswertung gelöscht.
      </P>
      <H2>VERWENDUNG VON COOKIES</H2>
      <P>
        Auf unserer Website verwenden wir Session-Cookies, um Ihnen die Nutzung
        unserer Webseiten zu erleichtern. Dabei handelt es sich um kleine
        Textdateien, welche für die Dauer Ihres Besuchs auf unserer Webseite auf
        Ihrer Festplatte gespeichert und abhängig von der Einstellung Ihres
        Browser-Programms beim Beenden des Browsers wieder gelöscht werden. Das
        Speichern von Cookies kann in den Einstellungen Ihres Browsers
        ausgeschaltet oder deaktiviert werden. Session-Cookies werden
        automatisch gelöscht, wenn Sie Ihren Browser schließen.
        Persistent-Cookies überdauern das Schließen des Browsers.
      </P>
      <H2>KONTAKT MIT UNS</H2>
      <P>
        Wenn Sie Fragen oder Wünsche haben, stehen wir Ihnen gerne zur
        Verfügung. Wir verarbeiten Ihre Angaben zur Beantwortung Ihrer Produkt-
        oder Serviceanfragen (Art.6 Abs.1 b, f DSGVO). Die Mitteilung von als
        Pflichtangaben gekennzeichneten Adress- und Telekommunikationsdaten ist
        dabei erforderlich, um Ihr Anliegen bearbeiten und beantworten zu
        können. Die freiwillige Angabe weiterer Daten erleichtert uns die
        Bearbeitung Ihrer Anfrage. Die Angaben aus Ihrer Anfrage speichern wir
        nach Beantwortung der Anfrage grundsätzlich für sechs Jahre, wenn es
        sich um Handels- oder Geschäftsbriefe handelt (§ 257 Abs.4 HGB, Art. 6
        Abs.1 c DSGVO).
      </P>
      <H2>DATENVERARBEITUNG ZU WERBEZWECKEN</H2>
      <P>
        Wir nutzen Ihre Daten nur für eigene Werbezwecke. Wir möchten Sie über
        neue Produkte, unsere Serviceleistungen sowie interessante
        Veranstaltungen informieren. Daher nutzen wir Ihre Daten auch, um Ihnen
        über Ihre Käufe hinaus bestimmte Produkte, Serviceleistungen oder
        Veranstaltungen zu empfehlen, die Sie interessieren könnten. Die
        Auswertung der Kundenhistorie und der Versand entsprechender Werbung per
        Post oder E-Mail an Bestandskunden erfolgt unter Beachtung Ihrer
        berechtigten Interessen nur für Produkte und Serviceleistungen der
        Control€xpert GmbH. Die Versendung per E-Mail erfolgt nur mit Ihrer
        Einwilligung, die Sie mit Wirkung für die Zukunft jederzeit widerrufen
        können. Newsletter werden an Sie nur auf Grund einer gesonderten
        Einwilligung versendet. Weitere Infos zu Ihren Rechten finden Sie unter
        der Überschrift „Betroffenenrechte“.
      </P>
      <H2>WEITERE EMPFÄNGER DER PERSONENBEZOGENEN DATEN</H2>
      <P>
        Im Rahmen der gesetzlichen Befugnisse werden Ihre personenbezogenen
        Daten ggf. gegenüber folgenden Firmen innerhalb der EU offengelegt:
      </P>
      <Ul>
        <Li>
          Postdienstleister, Versanddienstleister, Akten-/Datenträgerentsorgung
        </Li>
        <Li>IT Dienstleister im Rahmen der Wartung und Softwarepflege</Li>
        <Li>softgarden e-recruiting GmbH (im Bewerbungsverfahren)</Li>
      </Ul>
      <H2>DAUER DER DATENSPEICHERUNG</H2>
      <P>
        Wir speichern Ihre Daten so lange, wie es der jeweilige Zweck erfordert.
        Entsprechende Löschkonzepte sind vorhanden und werden laufend den
        gesetzlichen sowie erforderlichen Gegebenheiten angepasst. Soweit für
        bestimmte Daten, die für die Abwicklung von Kaufverträgen verarbeitet
        werden, eine steuerrechtliche Aufbewahrungsfrist besteht (Belegdaten),
        beläuft sich die Speicherfrist auf 6 bzw. 10 Jahre. Während dieser Zeit
        ist die Verarbeitung der Daten eingeschränkt. Die Aufbewahrungspflicht
        beginnt mit dem Ende des Kalenderjahres, in dem das Angebot abgegeben
        bzw. der Vertrag erfüllt wurde.
      </P>
      <H2>BETROFFENENRECHTE</H2>
      <P>
        Ihre Rechte als Betroffener werden durch die Control€xpert GmbH
        umfassend gewährleistet.
      </P>
      <H2>WERBEWIDERSPRUCH</H2>
      <P>
        Der Verwendung Ihrer Daten für werbliche Zwecke können Sie
        widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach
        den Basistarifen entstehen. Hierfür stehen Ihnen die oben genannten
        Kontakte zur Verfügung.
      </P>
      <H2>WIDERRUF EINER EINWILLIGUNG</H2>
      <P>
        Ihre Einwilligung in die Verarbeitung personenbezogener Daten können Sie
        jederzeit mit Wirkung für die Zukunft widerrufen. Bitte beachten Sie
        aber, dass damit vor einer erneuten Kontaktaufnahme oder Anfrage ggf.
        die erneute Angabe Ihrer Daten erforderlich wird.
      </P>
      <H2>WIDERSPRUCHSRECHT</H2>
      <P>
        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
        Situation ergeben, jederzeit Widerspruch gegen die Verarbeitung Ihrer
        personenbezogenen Daten einzulegen, die auf Grund der nachstehenden
        Vorschriften erfolgt:
      </P>
      <Ul>
        <Li>
          Die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich,
          die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
          Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder
        </Li>
        <Li>
          die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern, überwiegen,
          insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind
          handelt.
        </Li>
      </Ul>
      <P>
        Falls Sie Widerspruch einlegen, verarbeiten wir Ihre personenbezogenen
        Daten in diesem Fall nicht mehr, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
        Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen.
      </P>
      <H2>WEITERE RECHTE</H2>
      <P>
        Folgende Rechte stehen Ihnen bzgl. Ihrer gespeicherten personenbezogenen
        Daten dem Verantwortlichen gegenüber zu:
      </P>
      <Ul>
        <Li>Auskunft</Li>
        <Li>Berichtigung</Li>
        <Li>Löschung oder Einschränkung der Verarbeitung</Li>
        <Li>Widerspruch gegen die Verarbeitung</Li>
        <Li>Datenübertragbarkeit</Li>
        <Li>Beschwerderecht gegenüber Aufsichtsbehörden.</Li>
      </Ul>
      <H2>KONTAKTE: WIE KANN ICH MEINE VORSTEHENDEN RECHTE AUSÜBEN?</H2>
      <P>
        Um Ihre Rechte wahrzunehmen, können Sie sich an den
        Datenschutzbeauftragten (s.o) oder an den Verantwortlichen (s.o) wenden.
        Wir werden Ihre Anfragen umgehend sowie gemäß den gesetzlichen Vorgaben
        unentgeltlich bearbeiten und Ihnen mitteilen, welche Maßnahmen wir
        ergriffen haben.
      </P>
      <H2>BEWERBERDATENDATENSCHUTZ</H2>
      <P>
        Unser Bewerbungsverfahren wird durch die Firma softgarden e-recruiting
        GmbH als Auftragsverarbeiter bereitgestellt. Wir leiten Sie hierfür von
        der C€ Homepage auf das von softgarden betriebene Bewerbungsportal
        weiter. Die Datenschutzerklärung für das elektronische
        Bewerbungsverfahren können sie hier abrufen:
        https://controlexpert.softgarden.io/de/data-security
      </P>
      <H2>AKTUALISIERUNG UND ÄNDERUNG</H2>
      <P>
        Die Datenschutzerklärung muss von Zeit zu Zeit an die tatsächlichen
        Verhältnisse und an die Rechtslage angepasst werden. Bitte überprüfen
        Sie jeweils die Datenschutzerklärung, bevor Sie unser Angebot nutzen, um
        bei möglichen Änderungen oder Aktualisierungen auf dem neusten Stand zu
        sein.
      </P>
      <H2>SIE FINDEN, SO VIELE DATEN BEDEUTEN EINE GROSSE VERANTWORTUNG?</H2>
      <P>
        Richtig. Darum hält unser Datenschutzbeauftragter die Datensicherheit
        entsprechend aller datenschutzrechtlichen Anforderungen immer auf
        höchstem Niveau. Die Daten unserer Kunden werden vollständig getrennt
        verwaltet und sind jeweils nur innerhalb eines spezifischen Datenkreises
        verfügbar. Die Datenschutz-Grundverordnung, das Bundesdatenschutzgesetz
        und der Code Of Conduct der Versicherungswirtschaft sind Richtschnur
        unseres Handelns.
      </P>
    </Container>
  );
}

const H2 = styled.h2`
  font-family: "UnitCompPro-Ultra W04 Regular";
  text-transform: uppercase;
  line-height: 2rem;
  font-size: 1.5rem;
  padding: 2.5rem 0 2rem 0;
  color: ${(p) => p.theme.colors.light};
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding: 2.4rem 0 1rem 0;
  }
`;

const Ul = styled.ul`
  box-sizing: border-box;
  list-style-position: inside;
  padding-bottom: 1rem;
  color: ${(p) => p.theme.colors.light};
`;

const Li = styled.li`
  line-height: 1.5rem;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;
