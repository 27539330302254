import * as React from "react";
import styled from "styled-components";
import Checkmark from "../svg/Checkmark";
import large from "../../img/landingpage/bike_jgsmb4/bike_jgsmb4_ar_16_9,c_fill,g_auto__c_scale,w_2048.jpg";
import medium from "../../img/landingpage/bike_jgsmb4/bike_jgsmb4_ar_16_9,c_fill,g_auto__c_scale,w_1044.jpg";
import small from "../../img/landingpage/bike_jgsmb4/bike_jgsmb4_ar_16_9,c_fill,g_auto__c_scale,w_568.jpg";
import Underline from "../common/Underline";

const steps = [
  "Vom Anspruchsteller ausgefüllter Fragebogen",
  "Kopie des Anschaffungsbelegs",
  "Datensicherung durchgeführt",
  "Gerät transportsicher verpackt",
  "Alle Unterlagen im Paket",
];

export default function Inspection() {
  return (
    <Section id="inspection">
      <Container>
        <H2>
          <Underline>HELFEN SIE MIT</Underline> FÜR EINE SCHNELLE PRÜFUNG
        </H2>
        <Centered>
          <List>
            {steps.map((step, i) => (
              <Item key={i}>
                <StyledCheckmark />
                <span>{step}</span>
              </Item>
            ))}
          </List>
        </Centered>
      </Container>
      <Img
        alt="inspection"
        src={medium}
        srcSet={`${small} 568w, ${medium} 1044w, ${large} 2048w`}
      ></Img>
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  & > :not(:last-child) {
    margin-right: 2rem;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Container = styled.div`
  padding: 7rem 0 7rem 2rem;
  flex-grow: 1;
  flex-shrink: 1;
`;

const H2 = styled.h2`
  text-align: center;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  max-width: 700px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 2rem;
  list-style-type: none;
`;

const StyledCheckmark = styled(Checkmark)`
  color: ${(p) => p.theme.colors.primary};
  height: 2rem;
  padding-right: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  flex-grow: 1;
  flex-shrink: 1;
  width: 40%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
