import React, { useEffect } from "react";
import Contact from "../components/landingpage/Contact";
import Faq from "../components/landingpage/Faq";
import Footer from "../components/landingpage/Footer";
import Glasses from "../components/landingpage/Glasses";
import Header from "../components/landingpage/Header";
import Hero from "../components/landingpage/Hero";
import Inspection from "../components/landingpage/Inspection";
import MyMobileCheck from "../components/landingpage/MyMobileCheck";
import Services from "../components/landingpage/Services";
import useQueryParams from "../hooks/useQueryParams";

export default function Landingpage() {
  const { ceDamageNumber, insuranceDamageNumber } = useQueryParams();

  useEffect(() => {
    /** forward users coming from the qr-code link directly to the login form */
    if (ceDamageNumber && insuranceDamageNumber) {
      document
        .querySelector("#mymobilecheck")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [ceDamageNumber, insuranceDamageNumber]);

  return (
    <>
      <Header />
      <Hero />
      <Inspection />
      <MyMobileCheck />
      <Services />
      <Faq />
      <Glasses />
      <Contact />
      <Footer />
    </>
  );
}
