import * as React from "react";
import styled from "styled-components";
import Spinner from "../components/common/Spinner";
import Theme from "../Theme";

export default function Loading(props: { message?: React.ReactNode }) {
  return (
    <Container>
      <Spinner
        dotStyle={{
          size: "1rem",
          margin: "1rem",
          color: Theme.colors.primary,
        }}
      />
      {props.message && <Message>{props.message}</Message>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  & > :not(:last-child) {
    padding-bottom: 2rem;
  }
  padding: 3rem 2rem;
  min-height: calc(100vh - 6rem);
  min-width: calc(100vw - 4rem);
`;

const Message = styled.div`
  text-align: center;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.primary};
`;
