import * as React from "react";
import styled from "styled-components";
import { lighten, transparentize } from "polished";

import { CaseSearchMessages } from "../../services/PortalClient";
import { asErrorState, ValidationError } from "../validation/ValidationError";
import * as PortalAPI from "../../services/PortalClient";
import { useHistory } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useQueryParams from "../../hooks/useQueryParams";
import { ApiErrorContext } from "../../hooks/useApiError";

export default function LoginForm() {
  const { helperApi } = useApi();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [ceDamageNumber, setCeDamageNumber] = React.useState(
    queryParams.ceDamageNumber
  );
  const [insuranceDamageNumber, setInsuranceDamageNumber] = React.useState(
    queryParams.insuranceDamageNumber
  );
  const [errorState, setErrorState] = React.useState<
    PortalAPI.ErrorState | undefined
  >();

  const onGoToQuestionnaire = async () => {
    try {
      const response = await helperApi.apiMyMobileCheckGet(
        ceDamageNumber!,
        insuranceDamageNumber!
      );

      if (
        response.data.normalizedCeDamageNumber &&
        response.data.normalizedInsuranceDamageNumber
      ) {
        const cn = encodeURIComponent(response.data.normalizedCeDamageNumber);
        const $in = encodeURIComponent(
          response.data.normalizedInsuranceDamageNumber
        );

        history.push(`/questionnaire?cn=${cn}&in=${$in}`);
      }
    } catch (e) {
      setErrorState(asErrorState(e));
    }
  };

  const onViewState = async () => {
    if (ceDamageNumber || insuranceDamageNumber) {
      const cn = encodeURIComponent(ceDamageNumber ?? "");
      const $in = encodeURIComponent(insuranceDamageNumber ?? "");

      history.push(`/status?cn=${cn}&in=${$in}`);
    }
  };

  const handleInsuranceDamageNumber = (val: string) => {
    setInsuranceDamageNumber(val);
    setErrorState(undefined);
  };

  const handleCeDamageNumber = (val: string) => {
    setCeDamageNumber(val);
    setErrorState(undefined);
  };

  return (
    <ApiErrorContext.Provider value={{ errorState, setErrorState }}>
      <Container>
        <InputContainer>
          <TextInput
            id="insuranceDamageNumber"
            placeholder={CaseSearchMessages.InsuranceDamagenumberPlaceholder.toString()}
            onChange={(e) => handleInsuranceDamageNumber(e.target.value)}
            value={insuranceDamageNumber ?? undefined}
          />
          <Hint htmlFor="insuranceDamageNumber">
            {CaseSearchMessages.InsuranceDamagenumberHelpBlockText.toString()}
          </Hint>
        </InputContainer>
        <InputContainer>
          <TextInput
            id="ceDamageNumber"
            placeholder={CaseSearchMessages.CeDamageNumberPlaceholder.toString()}
            onChange={(e) => handleCeDamageNumber(e.target.value)}
            value={ceDamageNumber ?? undefined}
          />
          <Hint htmlFor="ceDamageNumber">
            {CaseSearchMessages.CeDamageNumberHelpBlockText.toString()}
          </Hint>
        </InputContainer>
        <ValidationError validationErrorNames={["damagenumbersnotfound", "questionnaire"]} />
        <CallToActionContainer>
          <PrimaryCallToAction
            id="login"
            onClick={onGoToQuestionnaire}
            disabled={!insuranceDamageNumber || !ceDamageNumber}
          >
            Zum Fragebogen
          </PrimaryCallToAction>
          <SecondaryCallToAction
            id="state"
            onClick={onViewState}
            disabled={!insuranceDamageNumber && !ceDamageNumber}
          >
            {CaseSearchMessages.CheckTrackingStatus.toString()}
          </SecondaryCallToAction>
        </CallToActionContainer>
      </Container>
    </ApiErrorContext.Provider>
  );
}

const Container = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  & > :not(:last-child) {
    margin-right: 2rem;
  }
  @media screen and (max-width: 470px) {
    flex-direction: column;
    margin-bottom: 1rem;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const PrimaryCallToAction = styled.button`
  font-size: 1rem;
  color: ${(p) =>
    p.disabled
      ? transparentize(0.5, p.theme.colors.dark)
      : p.theme.colors.dark};
  background-color: ${(p) =>
    p.disabled
      ? transparentize(0.5, p.theme.colors.tertiary)
      : p.theme.colors.tertiary};
  padding: 0.8rem 1.7rem;
  border-radius: 1.6rem;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-grow: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${(p) =>
        p.disabled
          ? transparentize(0.5, p.theme.colors.tertiary)
          : lighten(0.05, p.theme.colors.tertiary)};
    }
  }
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
`;

const SecondaryCallToAction = styled(PrimaryCallToAction)`
  background-color: transparent;
  color: ${(p) =>
    p.disabled
      ? transparentize(0.5, p.theme.colors.light)
      : p.theme.colors.light};
  border: 1px solid
    ${(p) =>
      p.disabled
        ? transparentize(0.5, p.theme.colors.light)
        : p.theme.colors.light};
  &:hover {
    background-color: ${(p) =>
      p.disabled ? "transparent" : p.theme.colors.light};
    color: ${(p) =>
      p.disabled
        ? transparentize(0.5, p.theme.colors.light)
        : p.theme.colors.primary};
  }
`;

const InputContainer = styled.div`
  & > :not(:last-child) {
    margin-bottom: 0.6rem;
  }
`;

const TextInput = styled.input`
  color: #000;
  text-align: center;
  background-color: ${(p) => p.theme.colors.light};
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  border-radius: 3px;
  width: calc(100% - 1.4rem);
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  &::placeholder {
    color: rgba(0, 0, 0, 0.55);
    opacity: 1;
  }
`;

const Hint = styled.label`
  font-size: 0.8rem;
  font-style: italic;
`;
