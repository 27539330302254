import * as React from "react";
import { ErrorState } from "../../services/PortalClient";
import { ServiceError } from "../../services/Services";
import { AxiosResponse } from "axios";
import styled from "styled-components";
import useApiError from "../../hooks/useApiError";

export function ValidationError(props: {
  readonly validationErrorNames?: string[];
}) {
  const { errorState } = useApiError();
  const validationErrorsModelState = errorState?.validationErrors?.modelState;

  const validationErrorsResults =
    validationErrorsModelState &&
    props.validationErrorNames?.map((a) => validationErrorsModelState[a]);

  const validationStringResults =
    validationErrorsResults && validationErrorsResults.join("\r\n");

  return (
    <>
      {validationStringResults && (
        <ValidationContainer>{validationStringResults}</ValidationContainer>
      )}
    </>
  );
}

const ValidationContainer = styled.div`
  color: ${(p) => p.theme.colors.danger};
  word-wrap: break-word;
`;

export function asErrorState(error: any): ErrorState | undefined {
  if (error?.isAxiosError) {
    const response = error.response as AxiosResponse;
    if (response?.data?.modelState) {
      return { validationErrors: response?.data };
    } else {
      return {
        serviceErrors: new ServiceError(
          response?.statusText,
          response?.status,
          response?.data
        ),
      };
    }
  }

  return undefined;
}
