import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../svg/ControlExpertLogoWhite";

export default function Footer() {
  return (
    <FooterContainer>
      <LogoContainer>
        <StyledLogo />
      </LogoContainer>
      <Container>
        <StyledLink to="/imprint">Impressum</StyledLink>
        <StyledLink to="/data-protection">Datenschutz</StyledLink>
      </Container>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
  color: ${(p) => p.theme.colors.light};
  background-color: ${(p) => p.theme.colors.primary};
  & > :not(:last-child) {
    margin-right: 1rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`;

const LogoContainer = styled.div`
  flex-grow: 1;
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.colors.light};
  opacity: 0.8;
  text-decoration: none;
`;

const StyledLogo = styled(Logo)`
  flex-shrink: 0;
  padding-right: 2rem;
  height: 2.3rem;
  fill: ${(p) => p.theme.colors.light};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 2rem;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-end;
    & > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`;
