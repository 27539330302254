import * as React from "react";
import { ErrorState } from "../services/PortalClient";

export interface IApiErrorContext {
  errorState: ErrorState | undefined;
  setErrorState:
    | React.Dispatch<React.SetStateAction<ErrorState | undefined>>
    | undefined;
}

const defaultContext: IApiErrorContext = {
  errorState: undefined,
  setErrorState: undefined,
};

export const ApiErrorContext = React.createContext(defaultContext);

export default function useApiError() {
  const { errorState, setErrorState } = React.useContext(ApiErrorContext);

  return {
    errorState,
    setErrorState,
    clearValidationErrors: (errors?: string[]) =>
      clearValidationErrors({ errorState, setErrorState }, errors),
  };
}

function clearValidationErrors(ctx: IApiErrorContext, errors?: string[]) {
  const { errorState, setErrorState } = ctx;
  errors?.forEach((n) => {
    if (
      errorState?.validationErrors?.modelState &&
      errorState?.validationErrors?.modelState[n]
    ) {
      delete errorState?.validationErrors?.modelState[n];
    }
  });

  setErrorState && setErrorState(errorState ? { ...errorState } : undefined);
}
