import styled from "styled-components";

const BackButton = styled.a`
  font-size: 1rem;
  padding: 2rem auto 0rem auto;
  color: ${(p) => p.theme.colors.light};
  flex-grow: 0;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: lighten(${(p) => p.theme.colors.secondary}, 10%);
    }
  }
`;

export default BackButton;
