/* eslint-disable no-extend-native */
/// <reference path="Global.d.ts"/>

// Signum Global.ts

String.prototype.after = function (this: string, separator: string) {
  const index = this.indexOf(separator);
  if (index === -1) return "";

  return this.substring(index + separator.length);
};

String.prototype.afterLast = function (this: string, separator: string) {
  const index = this.lastIndexOf(separator);
  if (index === -1) return "";

  return this.substring(index + separator.length);
};

Array.prototype.extract = function (
  this: any[],
  predicate: (element: any) => boolean
) {
  const result = this.filter(predicate);

  result.forEach((element) => {
    this.remove(element);
  });

  return result;
};

Array.prototype.max = function (
  this: any[],
  selector?: (element: any, index: number, array: any[]) => any
) {
  if (selector) return Math.max.apply(undefined, this.map(selector));

  return Math.max.apply(undefined, this);
};

Array.prototype.remove = function (this: any[], element: any) {
  const index = this.indexOf(element);
  if (index === -1) return false;

  this.splice(index, 1);
  return true;
};

export {};
