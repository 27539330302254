import * as React from "react";
import styled from "styled-components";
import StockimageSmall from "../../img/landingpage/glasses_w3dnnr/glasses_w3dnnr_c_scale,w_773.jpg";
import StockimageMedium from "../../img/landingpage/glasses_w3dnnr/glasses_w3dnnr_c_scale,w_1170.jpg";
import StockimageLarge from "../../img/landingpage/glasses_w3dnnr/glasses_w3dnnr_c_scale,w_1496.jpg";

export default function Glasses() {
  return (
    <Section>
      <Row>
        <Image
          alt="brille"
          src={StockimageMedium}
          srcSet={`${StockimageSmall} 773w, ${StockimageMedium} 1170w, ${StockimageLarge} 1496w`}
        />
        <Container>
          <Circle>
            JETZT
            <br />
            NEU
          </Circle>
          <Text>
            Prüfung Ihrer Brillenschäden durch unsere Optiker-Meister.
          </Text>
        </Container>
      </Row>
    </Section>
  );
}

const Section = styled.div`
  color: ${(p) => p.theme.colors.dark};
  background-color: ${(p) => p.theme.colors.tertiary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`;

const Image = styled.img`
  max-width: 40%;
  @media screen and (max-width: 700px) {
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 2rem 7rem;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  & > :not(:last-child) {
    margin-right: 2rem;
  }
`;

const Circle = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  background-color: ${(p) => p.theme.colors.dark};
  padding: 2rem;
  border-radius: 50%;
`;

const Text = styled.div`
  font-size: 1.1rem;
  text-align: center;
  font-style: italic;
`;
