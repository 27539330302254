import React from "react";

export default function Dot(
  props: React.SVGProps<SVGSVGElement> & {
    innerColor?: string;
    innerOpacity?: string;
    outerColor?: string;
    outerOpacity?: string;
  }
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
      <g transform="translate(-147 -87)">
        <circle
          cx="5"
          cy="5"
          r="5"
          transform="translate(147 87)"
          fill={props.outerColor ?? "currentColor"}
          opacity={props.outerOpacity ?? "0.3"}
        />
        <circle
          cx="2"
          cy="2"
          r="2"
          transform="translate(150 90)"
          fill={props.innerColor ?? "currentColor"}
        />
      </g>
    </svg>
  );
}
