import {
  Configuration,
  StatusApi,
  HelperApi,
  QuestionnaireApi,
} from "@ce/mobile-check-portal-client-typescript";

const configuration = new Configuration({
  basePath: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
});

const api = {
  statusApi: new StatusApi(configuration),
  helperApi: new HelperApi(configuration),
  questionnaireApi: new QuestionnaireApi(configuration),
};

export default function useApi() {
  return api;
}
