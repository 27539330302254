import React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { FadeInBottom } from "../animations/FadeInBottom";
import ChevronDown from "../svg/ChevronDown";
import StockHeroImage from "../../img/landingpage/hero/hero_e8bmaw_c_scale,w_1400.jpg";

export default function Hero() {
  return (
    <HeroSection>
      <HeroImage />
      <HeroColorOverlay />
      <HeroHeader>MobileCheck</HeroHeader>
      <HeroSubHeader>
        PRÜFUNG UND BEGUTACHTUNG VON ELEKTRONISCHEN GERÄTEN UND BRILLEN
      </HeroSubHeader>
      <HeroDescription>
        Nutzen Sie unseren online Fragebogen, damit wir Ihren Schaden
        schnellstmöglich bearbeiten können.
      </HeroDescription>
      <CallToActionContainer>
        <PrimaryCallToAction href="#mymobilecheck">
          Zum Fragebogen
        </PrimaryCallToAction>
        <SecondaryCallToAction href="#mymobilecheck">
          Statusabfrage
        </SecondaryCallToAction>
      </CallToActionContainer>
      <ScrollDownIconLink href="#inspection" aria-label="scroll down">
        <StyledChevronDown />
      </ScrollDownIconLink>
    </HeroSection>
  );
}

const HeroImage = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100vw;
  background-image: url(${StockHeroImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.8;
  z-index: -3;
`;

const HeroColorOverlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100vw;
  background-color: ${(p) => p.theme.colors.primary};
  opacity: 0.8;
  z-index: -2;
`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  padding: 7rem 1rem 7rem 1rem;
  box-sizing: border-box;
`;

const HeroHeader = styled.h1`
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 4rem;
  font-weight: 600;
  padding: 0rem 2rem 3rem 2rem;
  text-transform: uppercase;
  @media screen and (max-width: 500px) {
    font-size: 3rem;
  }
  ${FadeInBottom}
  @media screen and (max-height: 600px) {
    display: none;
  }
`;

const HeroSubHeader = styled.h2`
  line-height: 2.4rem;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
  ${FadeInBottom}
  animation-delay: .5s;
  @media screen and (max-height: 700px) {
    animation-delay: 0s;
  }
`;

const HeroDescription = styled.div`
  font-size: 1.2rem;
  padding: 0 6rem 4rem 6rem;
  line-height: 2.4rem;
  @media screen and (max-width: 450px) {
    padding: 0 2rem 3rem 2rem;
  }
  ${FadeInBottom}
  animation-delay: 1s;
  @media screen and (max-height: 700px) {
    animation-delay: 0.6s;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  & > :not(:last-child) {
    margin-right: 4rem;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 1rem;
    & > :not(:last-child) {
      margin-right: 0rem;
    }
  }
`;

const PrimaryCallToAction = styled.a`
  font-size: 1rem;
  background-color: ${(p) => p.theme.colors.tertiary};
  padding: 0.8rem 1.7rem;
  border-radius: 1.6rem;
  color: ${(p) => p.theme.colors.dark};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-grow: 0;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${(p) => lighten(0.05, p.theme.colors.tertiary)};
    }
  }
  ${FadeInBottom}
  animation-delay: 1.25s;
  @media screen and (max-height: 700px) {
    animation-delay: 1s;
  }
`;

const SecondaryCallToAction = styled(PrimaryCallToAction)`
  background-color: transparent;
  color: ${(p) => p.theme.colors.light};
  border: 1px solid ${(p) => p.theme.colors.light};
  &:hover {
    background-color: ${(p) => p.theme.colors.light};
    color: ${(p) => p.theme.colors.primary};
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
  ${FadeInBottom}
  animation-delay: 1.5s;
  @media screen and (max-height: 700px) {
    animation-delay: 1.25s;
  }
`;

const ScrollDownIconLink = styled.a`
  position: absolute;
  cursor: pointer;
  bottom: 3rem;
  @media screen and (max-width: 450px) {
    bottom: 2rem;
  }
  ${FadeInBottom}
  animation-delay: 1.75s;
  @media screen and (max-height: 700px) {
    animation-delay: 1.5s;
  }
`;

const StyledChevronDown = styled(ChevronDown)`
  height: 2rem;
  color: ${(p) => p.theme.colors.light};
`;
