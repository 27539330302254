import React from "react";
import styled from "styled-components";
import { darken, lighten } from "polished";
import ControlExpertLogo from "../svg/ControlExpertLogo";

export default function Header() {
  return (
    <Container>
      <StyledLogo />
      <Nav>
        <NavLink href="#inspection">
          <NavLinkText>Prüfung</NavLinkText>
        </NavLink>
        <NavLink href="#services">
          <NavLinkText>Unsere Leistungen</NavLinkText>
        </NavLink>
        <NavLink href="#faq">
          <NavLinkText>Häufige Fragen</NavLinkText>
        </NavLink>
        <NavLink href="#contact">
          <NavLinkText>Kontakt</NavLinkText>
        </NavLink>
        <NavButton href="#mymobilecheck">Mein Mobilecheck</NavButton>
      </Nav>
    </Container>
  );
}

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  color: ${(p) => p.theme.colors.dark};
  background-color: rgba(235, 235, 235, 0.9);
  backdrop-filter: blur(4px);
  opacity: 0.98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  z-index: 5;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem;
  }
`;

const StyledLogo = styled(ControlExpertLogo)`
  flex-shrink: 0;
  padding-right: 2rem;
  cursor: pointer;
  height: 2.3rem;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const NavLinkText = styled.span`
  position: relative;
  padding-bottom: 0.3rem;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${(p) => lighten(0.1, p.theme.colors.dark)};
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
`;

const NavLink = styled.a`
  font-family: "UnitCompPro-Ultra W04 Regular";
  display: "flex";
  align-items: center;
  color: ${(p) => lighten(0.1, p.theme.colors.dark)};
  text-decoration: none;
  transition: border-bottom 0.3s ease-in;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const NavButton = styled.a`
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  color: ${(p) => p.theme.colors.primary};
  text-decoration: none;
  border: 1px solid ${(p) => p.theme.colors.primary};
  padding: 0.6rem 1.7rem;
  border-radius: 1.4rem;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s,
    border-color 0.3s ease-in-out 0s;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${(p) => darken(0.1, p.theme.colors.primary)};
      border-color: ${(p) => darken(0.1, p.theme.colors.primary)};
      color: ${(p) => p.theme.colors.light};
    }
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
