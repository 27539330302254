import { ServiceError } from "./Services";
import {
  QuestionnaireState,
  ValidationError,
} from "@ce/mobile-check-portal-client-typescript";

export interface FieldValidationProps {
  readonly validationErrorNames?: string[];
  readonly mandatory?: boolean;
  readonly helpBlock?: string;
}

export interface FieldValidationState {
  readonly errorState?: ErrorState;
  readonly validationErrorNames?: string[] | null;
  readonly helpOpened?: boolean;
}

export interface ErrorState {
  readonly validationErrors?: ValidationError | null;
  readonly serviceErrors?: ServiceError | null;
}

const states = [
  QuestionnaireState.New,
  QuestionnaireState.Introduction,
  QuestionnaireState.PersonalData,
  QuestionnaireState.Triage,
  QuestionnaireState.DamageCause,
  QuestionnaireState.DamageImages,
  QuestionnaireState.DamageDescription,
  QuestionnaireState.DamageCapturing,
  QuestionnaireState.DamageCircumstances,
  QuestionnaireState.FinalDecision,
  QuestionnaireState.Finished,
];

export function nextState(state: QuestionnaireState): QuestionnaireState {
  return states[states.indexOf(state) + 1];
}

export function previousState(state: QuestionnaireState): QuestionnaireState {
  return states[states.indexOf(state) - 1];
}

//Zeichen 	Unicode
//------------------------------
//�, � 		\u00c4, \u00e4
//�, � 		\u00d6, \u00f6
//�, � 		\u00dc, \u00fc
//� 		   \u00df
//�        \u20ac
//�        \u00a7

export enum FinishedMessages {
  Finished = "Der Vorgang wurde abgeschlossen.",
  NextStep = "Wie geht es jetzt weiter?",
  NextStepDescription = "Ihre vollständigen Angaben wurden automatisch an die entsprechende Fachabteilung übertragen, die für Ihren Vorgang zuständig ist. Wurden Sie im Anschreiben dazu aufgefordert die beschädigte Sache einzuschicken, bitten wir Sie dieses als nächstes zu tun (datailierte Angaben finden Sie in Ihrem Anschreiben). Wir informieren Sie nach Abschluss der Bearbeitung umgehend über das Ergebnis.",
  FinalReport = "Abschlussbericht",
}

export enum FinalDecisionMessages {
  TrackingStatusLegend = "Statusbenachrichtigung",
  TrackingStatusLabel = "Bitte halten Sie mich per Email \u00fcber den Bearbeitungsstatus meines Schadens auf dem laufenden.",
  TrackingEMailAddress = "Statusbenachrichtigungs Email Adresse",
  TrackingEmailPlaceholder = "EMail",
  RepairAndDiscardConfirmedLegend = "Sie haben einer Reparatur / Austausch oder einer Entsorgung zugestimmt, bitte best\u00e4tigen Sie Ihre Auswahl",
  RepairAndSendBackConfirmedLegend = "Sie haben einer Reparatur / Austausch oder einem Rückversand zugestimmt, bitte best\u00e4tigen Sie Ihre Auswahl",
  DiscardConfirmedLegend = "Sie haben einer Entsorgung zugestimmt, bitte best\u00e4tigen Sie Ihre Auswahl",
  SendBackConfirmedLegend = "Sie haben einem R\u00fcckversand zugestimmt, bitte best\u00e4tigen Sie Ihre Auswahl",
  RepairConfirmedLegend = "Sie haben einer Reparatur / Austausch zugestimmt, bitte best\u00e4tigen Sie Ihre Auswahl",
  RepairNotPossibleHelpText = "Was soll mit dem Schadengut geschehen wenn eine Reparatur / Austausch nicht möglich ist (z.B bei Totalschaden)",
  Admonition = "Abschlusserkl\u00e4rung",
  FinalDecisionHelpBlock = "Die Kosten f\u00fcr eine Reparatur / Austausch, Entsorgung und/oder R\u00fcckversand tr\u00e4gt die Versicherung. Beachten Sie bitte weiterhin, dass Ihre Daten verloren gehen k\u00f6nnen und sorgen Sie f\u00fcr eine Datensicherung. ",
}

export enum Mobile3DMessages {
  SmallStroke = "Kleiner Pinsel",
  MediumStroke = "Mittlerer Pinsel",
  LargeStroke = "Gro\u00dfer Pinsel",
  OldDamageTitle = "Alte Sch\u00e4den",
  NewDamageTitle = "Neue Sch\u00e4den",
  ClearMarksTitle = "L\u00f6sche Markierungen",
  Confirmed = "Best\u00e4tigung",
  Turn = "Drehen",
}

export enum CircumstancesOfTheLossMessages {
  HeightOfFell = "Fallh\u00f6he",
  HeighOfFellLabel = "Aus welcher H\u00f6he fiel ",
  AsTookPlaceTheActionOfPressureLabel = "Wie erfolgte die Druckeinwirkung?",
  KindOfLiquidsLabel = "Um welche Art von Fl\u00fcssigkeit handelte es sich genau?",
  AmountOfLiquidLabel = "Bitte geben Sie die Menge der Fl\u00fcssigkeit an:",
  DamageAfterLossEventPlaceholder = "Schildern Sie den Schaden...",
  DamagedComponentsElecLabel = "Was genau wurde an dem Schadengut besch\u00e4digt?",
  DamagedComponentsGlassesLabel = "Was genau wurde an dem Schadengut besch\u00e4digt?",
  DamageAfterLossEventLabel = "Welche Besch\u00e4digung bzw. Fehlfunktion liegt nach dem Schadenereignis vor?",
  DamageAfterLossEventDescriptionLabel = "Beschreibung zu Fehlfunktionen oder Besch\u00e4digungen:",
  DeviceHasProtectiveFilmLabel = "War das Ger\u00e4t mit einer Displayfolie versehen?",
  DeviceWasInOperationLabel = "War das Ger\u00e4t beim Schadeneintritt in Betrieb?",
  DeviceWasConnectedToAccessoriesLabel = "Waren beim Schadeneintritt Leitungen oder Zubeh\u00f6r angeschlossen?",
  DeviceWasOpenLabel = "War das Ger\u00e4t beim Schadeneintritt ge\u00f6ffnet (z.B Display aufgeklappt bei Notebook)?",
  ThreeDModelExplainText = "Bitte markieren Sie die Besch\u00e4digungen und Kratzer die bereits vor dem Schadenereignis vorhanden waren in der Farbe Gr\u00fcn und die Besch\u00e4digungen nach dem Schadenreignis in Rot.",
  ThreeDModelExplainText2 = "Sie können mit der Handfunktion zwischen dem rotieren des 3D - Modells und zeichnen wechseln.",
  Mobile3DHelpBlock = "Bitte hier zur Best\u00e4tigung der Besch\u00e4digungen klicken",
}

export enum DamageDescriptionMessages {
  DamagedGoodIsLostLegend = "Angaben wenn das Schadengut unwiederbringlich verloren gegangen ist",
  UploadDamageDescriptions = "Hier Dateien zur Schadenschilderungen hochladen.",
  AdditionalInsuranceUsed = "Ich habe diese in Anspruch genommen.",
  AdditionalInsuranceName = "Name der Zusatzversicherung",
  AttachmentsHelpBlockText = "Bitte laden Sie hier keine Bilder von dem defekten Ger\u00e4t hoch, diese k\u00f6nnen Sie im n\u00e4chsten Schritt bei den Deteilangaben hochladen.",
  DamageCause = "Schadenschilderung",
  DamageCausePlaceHolder = "Bitte schildern Sie den Schadenhergang aus Ihrer Sicht so genau wie m\u00f6glich, ggf mit Skizze auf einem separaten Blatt, da\u00df Sie uns hochladen k\u00f6nnen.",
  DamageCauseHelpBlockText = "Bitte beschreiben Sie den Hergang zum Schadenereignis so detailliert wie m\u00f6glich.",
  DamageType = "Um welche Form von Schadenereignis handelt es sich?",
  DateOfDamage = "Wann wurde das Schadengut besch\u00e4digt?",
  AdditionalInsurance = "Es besteht eine Zusatzversicherung (z.B Wertgarantie, PlusSchutz)",
  DateOfDamageHeader = "Datumsangaben zum Schadenzeitpunkt",
  UploadDamageDescription = "Unterlagen zum Schadenverlauf",
}

export enum DeviceDescriptionMessages {
  SignOfUsageHelpBlockText = "Unter leichte Besch\u00e4digungen fallen z.B leichte Kratzer, Lackabsch\u00fcrfungen.",
  SignsOfUsage = "Es hatte leichte Gebrauchsspuren.",
  OldDamagesExistsHelpBlockText = "Unter Besch\u00e4digungen fallen z.B. tiefe Kratzer, Macken, Glasausbruch, technische Defekte.",
  OldDamagesExists = "Es hatte bereits Besch\u00e4digungen.",
  AlreadyConductedRepair = "Es wurde in der Vergangenheit schon mal repariert, die durchgef\u00fchrten Reparaturen hatten nichts mit dem Schadenereignis zu tun.",
  OwnRepairPerformed = "Ich habe einen eigenen Reparaturversuch unternommen.",
  DeviceWasWorkshopBefore = "Ich habe das Ger\u00e4t in eine Servicewerkstatt gegeben.",
  DeviceWasOpticianBefore = "Ich habe die Brille zu einem Optiker gegeben.",
  NewDevicePurchased = "Ich habe mir bereits Ersatz gekauft.",
  IsAlreadyRepaired = "Ich habe das Schadengut bereits reparieren lassen. Ich werde es daher nicht einschicken.",
  CurrentEyesightSpecsDifferentToDamagedGlasses = "Meine aktuellen Sehwerte haben sich gegen\u00fcber der besch\u00e4digten Brille ver\u00e4ndert.",
  GlassesUsage = "Wie wird die Brille verwendet?",
  StateBeforeDamage = "In welchem Zustand war das Schadengut vor dem Schadenereignis?",
  StateAfterDamage = "Was ist nach dem Schadenereignis passiert?",
  Yes = "Ja",
  No = "Nein",
  StatementOfPurchase = "Bitte hier den Eigentumsnachweiss zum Ersatzkauf hochladen.",
  RepairPermission = "Wer hat Ihnen dazu die Kostenfreigabe erteilt? Bitte laden sie die schriftliche Best\u00e4tigung hoch.",
  RepairInvoice = "Bitte hier die Reparaturrechnung zum reparierten Schadengut hochladen.",
}

export enum ConfirmedDeviceMessages {
  DeviceModelColorSearchHelpBlockText = "z.B. Samsung Galaxy s7 sm-g930f 32,00 gb schwarz",
  DeviceSearch = "Ger\u00e4te Suche",
}

export enum QuestionnairePageMessages {
  Instruction = "Einf\u00fchrung",
  PersonalData = "Pers\u00f6nliche Daten",
  HurtObject = "Schadengut",
  DamageDescription = "Schadenbeschreibung",
  CircumstancesOfTheLoss = "Detailangaben",
  Disposal = "Abschluss",
}

export enum MyMobileCheckMessages {
  LoadingStatePleaseWait = "Lade Status, bitte warten.",
  LoadingQuestionnairePleaseWait = "Lade Fragebogen, bitte warten.",
  CheckStatus = "Zur Statusabfrage",
  OnlineQuestionnaire = "Zum Online Fragebogen",
}

export enum CaseSearchMessages {
  CheckTrackingStatus = "Statusabfrage",
  CeDamageNumberPlaceholder = "C\u20ac-Vorgangsnummer / Unser Zeichen eingeben",
  CeDamageNumberHelpBlockText = "Sie finden die Nummer auf Ihrem Anschreiben unter der Position: Unser Zeichen",
  InsuranceDamagenumberPlaceholder = "Schadennummer der Versicherung eingeben",
  InsuranceDamagenumberHelpBlockText = "Sie finden die Nummer auf Ihrem Anschreiben unter der Position: Haftpflichtschaden",
}

export enum ApiMessages {
  UnknownValidationError = "Unbekannter Validierungsfehler aufgetreten: ",
}

export enum ShowErrorMessages {
  SorryThereIsAnError = "Ups, es ist leider ein Fehler aufgetreten: ",
}

export enum ElementsMessages {
  MandatoryField = "Pflichtfeld",
  PleaseChoose = "Bitte w\u00e4hlen",
}

export enum InspectionMessages {
  InstructionsRead = "Ich habe die Hinweise gelesen und akzeptiert.",
  HintsOnlineQuestionnaire = "Hinweise zu diesem Online Fragebogen",
  DataProtectionDSGVO = "Information über die Erhebung von personenbezogenen Daten bei der betroffenen Person (Art. 13 DSGVO)",
}

export enum BankMessages {
  AccountHolder = "Name des Kontoinhabers",
  IBAN = "IBAN",
  BankName = "Name des Kreditinstituts",
  BankAccountInfo = "Beachten Sie bitte dass wenn Sie Ihre Bankdaten nicht angeben, und es zu Auszahlungen kommen sollte, es zu Verz\u00f6gerungen kommen kann.",
  BankAccountInfoUnknownValues = "Wenn Ihre Bankdaten von unserem System nicht erkannt werden, lassen Sie bitte alle Felder leer.",
}

export enum PersonMessages {
  IsCompany = "Das Schadengut gehört einer Firma",
  EntitledToDeductVAT = "Ich bin vorsteuerabzugsberechtigt",
  EntitledToDeductVATHelpBlockText = " Vom Vorsteuerabzug ausgenommen sind Firmen und Freiberufler, die als Kleinunternehmer\n nach \u00A719 Umsatzsteuergesetz auftreten sowie Privatpersonen.",
  Street = "Strasse",
  Housenumber = "Hausnr.",
  City = "Stadt",
  Postcode = "PLZ",
  Country = "Land",
  Salutation = "Anrede",
  Title = "Titel",
  FirstName = "Vorname",
  LastName = "Nachname",
  CompanyName = "Firmenname",
  ContactPerson = "Ansprechpartner",
}

export enum DeviceMessages {
  Attachments = "Anh\u00e4nge",
  DeviceType = "Hier sehen Sie die Ger\u00e4tegruppe die uns von der Versicherung mitgeteilt wurde.",
  ProofOfPurchase = "Eigentumsnachweis",
  AgeOfSecondHandDevice = "Bitte Alter am Kauftag angeben",
  AgeOfSecondHandDeviceIn = "Alter des gebrauchten Ger\u00e4tes in",
  PurchasePrice = "Kaufpreis",
  SelectedDevice = "Gew\u00e4hltes Ger\u00e4t",
  BankNameHelpBlockText = "Hier k\u00f6nnen Sie den Namen Ihrer Bank ausw\u00e4hlen, wenn dieser von dem automatisch ausgew\u00e4hlten Feld abweicht.",
  DeviceGroup = "Ger\u00e4te Gruppe",
  DeviceTypeNotImplemented = "DeviceType konnte nicht erkannt werden.",
  ManufacturerHelpBlockText = "Sollte der Hersteller nicht in der Liste zu finden sein, w\u00e4hlen Sie bitte Unbekannt aus.",
  ModellHelpBlockText = "So finden Sie Ihr Handy-Modell heraus. Gucken Sie auf dem Originalkarton, dort ist ein Aufkleber aufgedruckt der die interne Modellbezeichnung des Herstellers angibt. Bei vielen Modellen mit abnehmbaren Akku finden Sie einen Aufkleber der ebenfalls die Modelldaten beinhaltet. Eine weitere M\u00f6glichkeit ist \u00fcber die Telefonfunktion *#1111# ein und best\u00e4tigen Sie den Anruf mittels gr\u00fcnem H\u00f6rer, nach kurzer Zeit erscheint die Modellnummer. \u00dcber die Einstellungen im Ger\u00e4t kann man ebenfalls die Modellnummer auslesen. \u00d6ffnen Sie daf\u00fcr die Einstellungen und w\u00e4hlen Sie den Eintrag Info bzw. Ger\u00e4teinformationen. Alternativ kann die Option auch \u00dcber das Telefon hei\u00dfen.",
  AlreadyRepairedCost = "Wie hoch waren die Reparaturkosten?",
  AlreadyRepairedFrame = "Wie hoch waren die Reparaturkosten?",
  AlreadyRepairedCostFrame = "Wie hoch waren die Reparaturkosten für die Fassung?",
  AlreadyRepairedCostLeftLens = "Wie hoch waren die Reparaturkosten für das linke Glas?",
  AlreadyRepairedCostRightLens = "Wie hoch waren die Reparaturkosten für das rechte Glas?",
}
