import styled from "styled-components";

const Underline = styled.span<{ readonly color?: string }>`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    ${(p) => (p.color ? p.color : p.theme.colors.tertiary)} 50%
  );
`;

export default Underline;
