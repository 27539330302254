import * as React from "react";
import LoginForm from "./LoginForm";
import styled from "styled-components";
import Underline from "../common/Underline";
import { darken } from "polished";
import Theme from "../../Theme";

export default function MyMobileCheck() {
  return (
    <Section id="mymobilecheck">
      <H2>
        <Underline color={darken(0.1, Theme.colors.secondary)}>MEIN</Underline>{" "}
        MOBILECHECK
      </H2>
      <LoginForm />
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${(p) => p.theme.colors.light};
  background-color: ${(p) => p.theme.colors.primary};
  padding: 7rem 4rem 7rem 4rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const H2 = styled.h2`
  text-align: center;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
`;
