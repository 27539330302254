import "./polyfills";

import "./moment";
import "./numbro";

import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import SentryService from "./services/SentryService";

import Loading from "./pages/Loading";

import Landingpage from "./pages/Landingpage";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";

import Theme from "./Theme";

const Questionnaire = React.lazy(() => import("./pages/Questionnaire"));
const Status = React.lazy(() => import("./pages/Status"));

SentryService.init(
  "https://de128ce25e3a4b83a4a4298ed7446a86@sentry-relay.controlexpert.com/169"
);

export default function App() {
  return (
    <ThemeProvider theme={Theme as typeof Theme}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              <Landingpage />
            </Route>
            <Route path="/questionnaire">
              <Questionnaire />
            </Route>
            <Route path="/status">
              <Status />
            </Route>
            <Route path="/imprint">
              <Imprint />
            </Route>
            <Route path="/data-protection">
              <DataProtection />
            </Route>
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
}
