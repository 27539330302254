import { useEffect } from "react";

export default function useScrollToTop(
  ref?: React.MutableRefObject<HTMLElement>
) {
  useEffect(() => {
    if (ref?.current?.scrollTo) {
      ref.current.scrollTo(0, 0);
    } else if (window?.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [ref]);
}
