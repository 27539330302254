import React from "react";

export default function ControlExpertLogoWhite(
  props: React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Ebene_1"
      data-name="Ebene 1"
      viewBox="0 0 272.07 68.61"
      {...props}
    >
      <path
        d="M26.28,34.11a20.2,20.2,0,0,1-9.6,2.4c-10.15,0-16-6.95-16-16.6S7.33,2.76,17.33,2.76a18.34,18.34,0,0,1,8.3,1.75V8.66a16.73,16.73,0,0,0-8.2-2.1c-7.6,0-12.1,5.8-12.1,13.15,0,7.65,4.55,13,11.8,13A16,16,0,0,0,25,30.56Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M27.41,24.13c0-7,4.45-12.4,11.25-12.4s11.2,5.4,11.2,12.4S45.41,36.48,38.61,36.48,27.41,31.08,27.41,24.13Zm17.9,0c0-5.1-2.5-8.75-6.65-8.75S32,19,32,24.13s2.5,8.7,6.65,8.7S45.31,29.18,45.31,24.13Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M52.86,12.33h3.75l.5,2.9c2.55-2.1,5.2-3.5,8.2-3.5,3.75,0,7.2,2.2,7.2,8.4V36h-4.4V21.58c0-3.35-.8-5.95-4-5.95-2.4,0-4.7,1.4-6.8,3.2V36h-4.4Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M78.61,30.68V15.88h-4V12.33h4V6.93L83,5.53v6.8h7.1l-1.2,3.55H83V29.83c0,2.1.85,3,2.4,3a7.3,7.3,0,0,0,3.8-1.25l1.45,3.05a11.26,11.26,0,0,1-6.25,1.85C80.76,36.48,78.61,34.38,78.61,30.68Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M93.66,12.33h3.7l.5,2.9c2.5-2.65,4.5-3.5,6.65-3.5a5.54,5.54,0,0,1,2.8.7l-1.55,3.9a4.74,4.74,0,0,0-2.25-.6c-1.7,0-3.25.9-5.45,3.05V36h-4.4Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M106.46,24.13c0-7,4.45-12.4,11.25-12.4s11.2,5.4,11.2,12.4-4.45,12.35-11.25,12.35S106.46,31.08,106.46,24.13Zm17.9,0c0-5.1-2.5-8.75-6.65-8.75S111,19,111,24.13s2.5,8.7,6.65,8.7S124.36,29.18,124.36,24.13Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M132.16.58h4.4v29.5c0,1.8.55,2.35,1.8,2.35a5.36,5.36,0,0,0,1.45-.3l.9,3.46a10.5,10.5,0,0,1-3.65.69c-3.3,0-4.9-1.8-4.9-5Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M179.76,24.13l-8.3-11.8h4.85l6,8.7,6.15-8.7h4.7L184.71,24,193.11,36h-4.75l-6.2-8.85L175.91,36h-4.75Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M195.76,13.18a24.74,24.74,0,0,1,8.15-1.45c7.85,0,12.3,4.6,12.3,12.3,0,7.55-5,12.45-12.05,12.45a11.17,11.17,0,0,1-4-.75V47.84h-4.4ZM204,32.73c5,0,7.7-3.45,7.7-8.75,0-5.85-3.3-8.55-7.9-8.55a12.31,12.31,0,0,0-3.6.55v15.9A8.15,8.15,0,0,0,204,32.73Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M219,24c0-7.2,4-12.25,10-12.25,6.2,0,9.8,4.75,9.8,12.3v1.1h-15.3c.1,4.45,2.8,7.75,7.5,7.75a12.12,12.12,0,0,0,5.85-1.6l1.4,3.06a15.08,15.08,0,0,1-7.8,2.14C222.61,36.48,219,30.73,219,24Zm15.25-2.15c-.1-3.3-1.7-6.6-5.25-6.6-3.4,0-5.45,3-5.5,6.6Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M242,12.33h3.7l.5,2.9c2.5-2.65,4.5-3.5,6.65-3.5a5.54,5.54,0,0,1,2.8.7l-1.55,3.9a4.74,4.74,0,0,0-2.25-.6c-1.7,0-3.25.9-5.46,3.05V36H242Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M260.65,30.68V15.88h-4V12.33h4V6.93L265,5.53v6.8h7.11L271,15.88H265V29.83c0,2.1.86,3,2.41,3a7.3,7.3,0,0,0,3.8-1.25l1.45,3.05a11.26,11.26,0,0,1-6.25,1.85C262.79,36.48,260.65,34.38,260.65,30.68Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M168.57,30.56a16,16,0,0,1-7.9,2.15,11.1,11.1,0,0,1-11.22-8.58H163l1.25-3.52H148.9c0-.3,0-.59,0-.9a16.58,16.58,0,0,1,.46-3.92h15.32l1.25-3.47H150.69A11.34,11.34,0,0,1,161,6.56a16.73,16.73,0,0,1,8.2,2.1V4.51a18.34,18.34,0,0,0-8.3-1.75,16,16,0,0,0-15.11,9.56h-4.38v3.47h3.24a19.85,19.85,0,0,0-.45,4.12c0,.24,0,.47,0,.7h-2.82v3.52h3.21c1.5,7.39,7,12.38,15.63,12.38a20.2,20.2,0,0,0,9.6-2.4Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M3.76,49.93H7a6,6,0,0,1,3.14.69,3.53,3.53,0,0,1,1.81,3.22,3.65,3.65,0,0,1-2.84,3.72v0a7,7,0,0,1,1.72,2.28L13.3,64.1H11.07L8.75,60.16C8.08,59,7.54,58.34,7.13,58.23H5.67V64.1H3.76Zm3.18,6.73a3.47,3.47,0,0,0,1.83-.45A2.59,2.59,0,0,0,9,52.08,3.28,3.28,0,0,0,7,51.5H5.67v5.16Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M14.08,58.94c0-3.1,1.7-5.27,4.3-5.27s4.21,2,4.21,5.29v.47H16a3.13,3.13,0,0,0,3.23,3.33,5.26,5.26,0,0,0,2.51-.68l.6,1.31a6.44,6.44,0,0,1-3.35.92C15.63,64.31,14.08,61.84,14.08,58.94ZM20.64,58c-.05-1.42-.74-2.84-2.26-2.84S16,56.46,16,58Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M24,59.13a5.23,5.23,0,0,1,5.38-5.46,5.81,5.81,0,0,1,1.59.19v-5h1.89V63.6a7.76,7.76,0,0,1-3.4.71C26.46,64.31,24,62.53,24,59.13Zm7,3.33v-7a4.35,4.35,0,0,0-1.49-.24A3.49,3.49,0,0,0,26,59.09a3.32,3.32,0,0,0,3.55,3.61A4.07,4.07,0,0,0,31,62.46Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M34.93,58.94c0-3.1,1.7-5.27,4.3-5.27s4.22,2,4.22,5.29v.47H36.87a3.12,3.12,0,0,0,3.22,3.33,5.3,5.3,0,0,0,2.52-.68l.6,1.31a6.46,6.46,0,0,1-3.35.92C36.48,64.31,34.93,61.84,34.93,58.94ZM41.49,58c0-1.42-.73-2.84-2.26-2.84S36.89,56.46,36.87,58Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M46.16,55.45h-1.4V53.93h1.4V52.27c0-2.47,1.48-3.4,3.11-3.4a4.16,4.16,0,0,1,1.74.37l-.38,1.53a3.25,3.25,0,0,0-1.16-.24c-1,0-1.42.6-1.42,1.68v1.72h2.6l-.5,1.52h-2.1V64.1H46.16Zm6-4.83a1.17,1.17,0,0,1,1.2-1.21,1.19,1.19,0,0,1,1.25,1.21,1.22,1.22,0,0,1-1.25,1.22A1.18,1.18,0,0,1,52.11,50.62Zm.28,3.31h1.89V64.1H52.39Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M56.9,53.93h1.62l.21,1.24a5.6,5.6,0,0,1,3.53-1.5c1.61,0,3.09.95,3.09,3.61V64.1H63.46V57.91c0-1.45-.34-2.56-1.74-2.56a4.74,4.74,0,0,0-2.92,1.37V64.1H56.9Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M67.7,50.62a1.17,1.17,0,0,1,1.2-1.21,1.19,1.19,0,0,1,1.25,1.21,1.22,1.22,0,0,1-1.25,1.22A1.18,1.18,0,0,1,67.7,50.62ZM68,53.93h1.89V64.1H68Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M72.49,53.93H74.1l.22,1.24a5.6,5.6,0,0,1,3.53-1.5c1.61,0,3.09.95,3.09,3.61V64.1H79.05V57.91c0-1.45-.34-2.56-1.74-2.56a4.79,4.79,0,0,0-2.93,1.37V64.1H72.49Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M82.42,66.25a2.85,2.85,0,0,1,1.87-2.56,1.42,1.42,0,0,1-.7-1.27,2.61,2.61,0,0,1,1.14-2,3.51,3.51,0,0,1-1.64-3.09A3.65,3.65,0,0,1,87,53.69a4.49,4.49,0,0,1,2,.39c.1.06.15.06.23,0a3,3,0,0,1,2.22-.84,4,4,0,0,1,.62.06l-.43,1.42a3.26,3.26,0,0,0-.62-.06,1.66,1.66,0,0,0-.9.23,3.54,3.54,0,0,1,1,2.56c0,2.22-1.63,3.7-4,3.7a3.93,3.93,0,0,1-1.29-.19,1.45,1.45,0,0,0-.45,1c0,2,6.68.19,6.68,4,0,1.89-1.85,3.22-5.05,3.22C84.19,69.19,82.42,68.07,82.42,66.25Zm7.72-.09c0-1.74-3-1.4-4.77-2a2.17,2.17,0,0,0-1.2,1.72c0,1.17,1.2,1.85,3,1.85S90.14,67.06,90.14,66.16Zm-.92-8.75a2.09,2.09,0,0,0-2.15-2.21,2,2,0,0,0-2.13,2.19,2.07,2.07,0,0,0,2.15,2.23A2,2,0,0,0,89.22,57.41Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M97.11,49.93h3.2a6,6,0,0,1,3.14.69,3.53,3.53,0,0,1,1.81,3.22,3.65,3.65,0,0,1-2.84,3.72v0a7,7,0,0,1,1.72,2.28l2.51,4.22h-2.23l-2.32-3.94c-.67-1.18-1.21-1.82-1.62-1.93H99V64.1H97.11Zm3.18,6.73a3.47,3.47,0,0,0,1.83-.45,2.6,2.6,0,0,0,.26-4.13,3.32,3.32,0,0,0-2.07-.58H99v5.16Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M108.65,62.38a5.63,5.63,0,0,1-.27-1.94V53.93h1.89V60.1a4.28,4.28,0,0,0,.19,1.5,1.56,1.56,0,0,0,1.63,1.06,3.57,3.57,0,0,0,2.61-1.27V53.93h1.89v7a13.38,13.38,0,0,0,.21,3.14h-1.67L114.8,63a4.93,4.93,0,0,1-3.31,1.35A2.89,2.89,0,0,1,108.65,62.38Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M119.34,48.87h1.89V61.56c0,.77.24,1,.78,1a2.09,2.09,0,0,0,.62-.13l.39,1.48a4.34,4.34,0,0,1-1.57.31,1.9,1.9,0,0,1-2.11-2.13Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M124,58.94c0-3.1,1.7-5.27,4.3-5.27s4.22,2,4.22,5.29v.47h-6.58a3.12,3.12,0,0,0,3.22,3.33,5.3,5.3,0,0,0,2.52-.68l.6,1.31a6.44,6.44,0,0,1-3.35.92C125.53,64.31,124,61.84,124,58.94Zm6.56-.93c0-1.42-.73-2.84-2.26-2.84s-2.34,1.29-2.36,2.84Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M134.24,62a5.11,5.11,0,0,0,2.62.77c1.14,0,2.07-.4,2.07-1.46,0-2.06-4.78-1.37-4.78-4.75,0-1.61,1.27-2.88,3.57-2.88a7.57,7.57,0,0,1,2.67.49V55.8a6.29,6.29,0,0,0-2.58-.58c-.95,0-1.79.28-1.79,1.24,0,2.07,4.78,1.34,4.78,4.82,0,2.09-1.77,3-3.87,3a6.26,6.26,0,0,1-3.29-.9Z"
        transform="translate(-0.63 -0.58)"
      />
      <path
        d="M142.54,63.06a1.16,1.16,0,1,1,2.32,0,1.16,1.16,0,1,1-2.32,0Z"
        transform="translate(-0.63 -0.58)"
      />
    </svg>
  );
}
