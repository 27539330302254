const Theme = {
  colors: {
    primary: "#005699",
    secondary: "#919a9f",
    tertiary: "#C5D65C",
    quaternary: "#554395",
    dark: "rgba(0, 0, 0, .95)",
    light: "rgba(255, 255, 255, .95)",
    danger: "#c94c38",
  },
};

export default Theme;
