import * as React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import Add from "../svg/Add";
import Remove from "../svg/Remove";

interface AccordionProps {
  readonly title: React.ReactNode;
  readonly children?: React.ReactNode;
}

export default function Accordion(props: AccordionProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <div>
      <TitleContainer onClick={() => setIsOpen(!isOpen)}>
        <span>{props.title}</span>
        {isOpen ? <StyledCloseIcon /> : <StyledOpenIcon />}
      </TitleContainer>
      <Content
        ref={contentRef}
        isOpen={isOpen}
        maxHeight={
          contentRef.current?.scrollHeight
            ? `${contentRef.current?.scrollHeight}px`
            : undefined
        }
      >
        {props.children}
      </Content>
    </div>
  );
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  & > :not(:last-child) {
    padding-right: 0.75rem;
  }
  padding: 1rem 0;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div<{
  isOpen?: boolean;
  maxHeight?: string;
}>`
  transition: all 0.3s ease-out;
  padding-bottom: ${(props) => (props.isOpen ? ".75rem" : "0")};
  max-height: ${(props) =>
    props.isOpen && props.maxHeight ? props.maxHeight : "0px"};
  overflow-y: hidden;
`;

const StyledOpenIcon = styled(Add)`
  flex-shrink: 0;
  flex-grow: 0;
  height: 1rem;
  color: ${(p) => transparentize(0.3, p.theme.colors.light)};
`;

const StyledCloseIcon = styled(Remove)`
  flex-shrink: 0;
  flex-grow: 0;
  height: 1rem;
  color: ${(p) => transparentize(0.3, p.theme.colors.light)};
`;
