import * as Sentry from "@sentry/browser";

/**
 * This service provides an integration to our Sentry instance for frontend error logging
 * running at https://sentry.controlexpert.com.
 */
export default class SentryService {
  /**
   * Initializes a window.onerror handler to submit all errors to our Sentry instance.
   * We do however not log to Sentry if the app is executed locally from localhost / 127.0.0.1.
   */
  public static init(dsn: string) {
    if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1"
    ) {
      Sentry.init({ dsn });
      window.onerror = (
        event: Event | string,
        source?: string,
        fileno?: number,
        columnNumber?: number,
        error?: Error
      ): void => {
        Sentry.configureScope((scope) =>
          scope.setExtras({ event, fileno, columnNumber })
        );
        Sentry.captureException(error);
      };
    }
  }
}
