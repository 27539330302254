import * as React from "react";
import styled from "styled-components";
import Accordion from "../common/Accordion";
import Underline from "../common/Underline";
import { darken, transparentize } from "polished";
import Theme from "../../Theme";

export default function Faq() {
  return (
    <Section id="faq">
      <H2>
        <Underline color={darken(0.1, Theme.colors.secondary)}>
          HÄUFIG GESTELLTE
        </Underline>{" "}
        FRAGEN
      </H2>
      <CategoryContainer>
        <Category>Allgemeine Fragen</Category>
        <Questions>
          <Accordion title="Muss ich das Schadengut einsenden?">
            <Answer>
              Ja, eine Ermittlung der Schadenhöhe kann nur am vorliegenden
              Schadengut durchgeführt werden. Hier reichen z.B. Fotos allein
              nicht aus.
            </Answer>
          </Accordion>
          <Accordion title="Muss ich meine Festplatte mit einsenden?">
            <Answer>
              Solange das vorliegende Schadenbild durch die Entnahme der
              Festplatte nicht verändert wird, steht einer Entnahme nichts im
              Wege. Beachten Sie bitte, dass eine Überprüfung der Festplatte
              dann natürlich nicht erfolgen kann.
            </Answer>
          </Accordion>
          <Accordion title="Ist das beigefügte Versandlabel versichert?">
            <Answer>
              Natürlich ist der Versand versichert und für Sie kostenlos.
              Beachten Sie bitte, dass Sie bei Abgabe einen Einlieferungsbeleg
              erhalten.
              <br />
              Bitte bewahren Sie diesen gut auf, es ist Ihr Versandnachweis.
            </Answer>
          </Accordion>
          <Accordion title="Wie lange dauert die Prüfung?">
            <Answer>
              Bei idealem Postweg dauert die reine Prüfung ca. 5 Tage.
              <br />
              Wir bitten um Ihr Verständnis, dass wir den logistischen Part
              nicht wirklich beeinflussen können.
              <br />
              Beachten Sie, dass eine Prüfung nur dann schnell und erfolgreich
              ist, wenn auch alle Unterlagen und Angaben vollständig sind.
            </Answer>
          </Accordion>
          <Accordion title="Warum muss ich die „Find my iPhone“ Funktion deaktivieren?">
            <Answer>
              Diese Funktion dient dem Schutz bei Diebstahl oder Verlust des
              Gerätes.
              <br />
              Ist diese Funktion aktiv, wird der Service vom Hersteller
              selbstverständlich verweigert.
            </Answer>
          </Accordion>
          <Accordion title="Was passiert mit meinen Daten?">
            <Answer>
              Wir arbeiten nach den Vorgaben des Bundesdatenschutzgesetzes und
              orientieren uns am „Code Of Conduct“ des „Gesamtverbandes der
              deutschen Versicherungswirtschaft“. Sofern Sie nicht explizit
              schriftlich bestätigt haben, dass Ihre Daten ausgelesen werden
              dürfen, werden wir keine personenbezogenen Daten verwenden.
            </Answer>
          </Accordion>
          <Accordion title="Was bedeutet „vorsteuerabzugsberechtigt“?">
            <Answer>
              Die Umsatz- (oder Mehrwert-)Steuer ist für den Unternehmer ein
              durchlaufender Posten. Sofern ein Unternehmer nicht als
              „Kleinunternehmer“ (Voraussetzung: Umsatz nicht höher als
              17.500.-€ im Jahr) optiert hat, kann er die entrichtete
              Umsatzsteuer als Vorsteuer mit der von ihm eingezogenen
              Umsatzsteuer im Wege des Vorsteuerabzuges verrechnen. Die
              Vorsteuerabzugsberechtigung ist also nur von Bedeutung, wenn Sie
              selbständig tätig sind, da Sie in diesem Fall nur die
              Nettoentschädigung erhalten (§249 Absatz2 BGB).
            </Answer>
          </Accordion>
        </Questions>
      </CategoryContainer>
      <CategoryContainer>
        <Category>Elektronisches Gerät</Category>
        <Questions>
          <Accordion title="Wie lange dauert eine Reparatur?">
            <Answer>
              Die Reparatur wird herstellerautorisiert durchgeführt. Die
              Laufzeiten unterliegen somit den Herstellerbedingungen. Nicht
              jeder Hersteller hat ausreichend Ersatzteile bevorratet, somit
              schwankt die Reparaturzeit. Wir sind in ständigem Kontakt mit den
              Herstellern, um die schnellste Reparatur zu erreichen.
            </Answer>
          </Accordion>
          <Accordion title="Bleibt meine Garantie bei einer Reparatur erhalten?">
            <Answer>
              Ja, eine bestehende Garantie bleibt durch die Werksreparatur
              erhalten.
            </Answer>
          </Accordion>
          <Accordion title="Wer bezahlt die Reparatur?">
            <Answer>
              Wenn ein eintrittspflichtiger Schaden vorliegt, werden die Kosten
              für eine Reparatur durch die zuständige Haftpflichtversicherung
              übernommen.
            </Answer>
          </Accordion>
          <Accordion title="Sind meine Daten nach der Reparatur noch auf dem Gerät?">
            <Answer>
              Nein, nach Herstellerkriterien wird nach erfolgreicher Reparatur
              eine Prüfsoftware aufgespielt, um das Gerät elektronisch zu
              testen, anschließend ist das Gerät datenclean. Ausnahme sind
              Festplatten, diese bleiben unberührt, es sei denn die Festplatte
              ist durch das Schadenereignis beschädigt und wird ausgewechselt.
            </Answer>
          </Accordion>
          <Accordion title="Habe ich einen Anspruch auf eine Datensicherung?">
            <Answer>
              Nein, die Rechtsprechung geht davon aus, dass bei wichtigen Daten
              der Dateninhaber eine regelmäßige Datensicherung durchführt.
              Beachten Sie bitte auch, dass sensible Daten auf einem mobilen
              Endgerät grundsätzlich nicht sicher aufgehoben sind.
              <br />
              Wir gehen grundsätzlich von datengesicherten Geräten aus.
            </Answer>
          </Accordion>
        </Questions>
      </CategoryContainer>
      <CategoryContainer>
        <Category>Brille</Category>
        <Questions>
          <Accordion title="Brauche ich einen Nachweis über eine Sehstärkenveränderung?">
            <Answer>
              Ja, da dieser zu einer einwandfreien Ermittlung der Schadenhöhe
              dient. Ein Nachweis kann durch den zuständigen Optiker oder durch
              den Augenarzt vorgelegt werden.
            </Answer>
          </Accordion>
          <Accordion title="Besteht die Möglichkeit meine Brille reparieren zu lassen?">
            <Answer>
              Brillen fallen als medizinische Produkte unter das
              Medizinproduktegesetz (MPG). Somit ergeben sich strenge
              Anforderungen an eine Reparatur / Instandsetzung. Die Ermittlung
              der individuellen Zentrierdaten, die Anpassung sowie eine
              Verträglichkeitsprüfung sind notwendig. Dadurch sind Reparaturen /
              Instandsetzungen im Bereich Brille nicht wirtschaftlich
              durchführbar, sodass wir diese z. Zt. nicht anbieten.
            </Answer>
          </Accordion>
        </Questions>
      </CategoryContainer>
      <CategoryContainer>
        <Category>Wertermittlung</Category>
        <Questions>
          <Accordion title="Zeitwert">
            <Answer>
              Die Haftpflichtversicherungen ersetzen in der Regel den Zeitwert.
              Dieser wird durch die Faktoren Neuwert, Alter und Zustand des
              Schadengutes bestimmt. Der festgestellte Zeitwert kann aufgrund
              von Gebrauchsspuren, Beschädigungen oder elektronischen Defekten,
              die nicht auf das Schadenereignis zurück zu führen sind, niedriger
              ausfallen. Denn wenn bereits Beschädigungen am Schadengut
              vorlagen, war der Wert des Schadengutes bereits vor
              Schadeneintritt niedriger als ohne Beschädigungen.
            </Answer>
          </Accordion>
          <Accordion title="Neuwert">
            <Answer>
              Neuwert ist der Wert eines Schadengutes, zu dem ein gleiches oder
              vergleichbares Gerät neu angeschafft werden kann. Dabei beachten
              Sie bitte, dass durch die technische Weiterentwicklung und der
              damit verbundenen Preisanpassung der angegebene Neuwert eines
              vergleichbaren Gerätes gegebenenfalls von dem ursprünglichen
              Anschaffungspreis deutlich abweichen kann.
            </Answer>
          </Accordion>
          <Accordion title="Restwert">
            <Answer>
              Wird das Gerät vom Geschädigten zurückgefordert und ist eine
              Reparatur nicht wirtschaftlich möglich, so kann ein Restwert vom
              Zeitwert abgezogen werden, sofern sich das Gerät auch im
              beschädigten Zustand noch vermarkten lässt.
            </Answer>
          </Accordion>
          <Accordion title="Gebrauchswertabzug">
            <Answer>
              Kann eine Brille nach der Begutachtung weiterhin als Ersatzbrille
              verwendet werden, so fällt bei einer gewünschten Rücksendung ein
              Gebrauchswertabzug an. (Notbrille: Besser als nichts)
            </Answer>
          </Accordion>
        </Questions>
      </CategoryContainer>
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${(p) => p.theme.colors.light};
  background-color: ${(p) => p.theme.colors.primary};
  padding: 7rem 4rem 7rem 0rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  & > :first-child {
    border-top: 1px solid ${(p) => transparentize(0.8, p.theme.colors.light)};
  }
  & > * {
    border-bottom: 1px solid ${(p) => transparentize(0.8, p.theme.colors.light)};
  }
`;

const CategoryContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Category = styled.h3`
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.2rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
`;

const H2 = styled.h2`
  text-align: center;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
`;

const Answer = styled.div`
  font-weight: lighter;
  font-style: italic;
`;
