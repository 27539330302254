import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import BackButton from "../components/common/BackButton";
import useScrollToTop from "../hooks/useScrollToTop";

export default function Imprint() {
  useScrollToTop();
  const history = useHistory();

  return (
    <Container>
      <BackButton
        onClick={() =>
          history.length > 0 ? history.goBack() : history.push("/")
        }
      >
        &larr; Zurück
      </BackButton>
      <H1>Impressum</H1>
      <P>
        Control€xpert GmbH
        <br />
        Marie-Curie-Straße 3
        <br />
        40764 Langenfeld
      </P>
      <P>
        Telefon: +49 2173-84984-460
        <br />
        Telefax: +49 2173-8498499
        <br />
        E-Mail:&nbsp;
        <A href="mailto:info@controlexpert.com">info(at)controlexpert.com</A>
      </P>
      <P>
        Geschäftsführer:
        <br />
        Sebastian Lins | Andreas Decker | Rüdiger Hackhausen
        <br />
      </P>
      <P>
        Handelsregister Düsseldorf
        <br />
        HRB 47037
        <br />
        Umsatzsteuer-ID DE 222 718 239
      </P>
    </Container>
  );
}

export const Container = styled.div`
  line-height: 2rem;
  word-wrap: break-word;
  min-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem;
  background-color: ${(p) => p.theme.colors.primary};
  @media screen and (max-width: 450px) {
    padding: 3rem 2rem;
    min-height: calc(100vh - 6rem);
  }
`;

export const H1 = styled.h1`
  font-family: "UnitCompPro-Ultra W04 Regular";
  text-transform: uppercase;
  line-height: 2rem;
  font-size: 2rem;
  padding: 3rem 0 2rem 0;
  color: ${(p) => p.theme.colors.light};
  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
    padding: 3.6rem 0 1rem 0;
  }
`;

export const P = styled.p`
  line-height: 2rem;
  padding-bottom: 1rem;
  color: ${(p) => p.theme.colors.light};
`;

const A = styled.a`
  color: ${(p) => p.theme.colors.light};
  text-decoration: none;
  cursor: pointer;
`;
