import { transparentize } from "polished";
import * as React from "react";
import styled from "styled-components";
import Underline from "../common/Underline";

export default function Contact() {
  return (
    <Section id="contact">
      <H2>
        <Underline>BEI WEITEREN FRAGEN</Underline> STEHEN WIR IHNEN GERNE ZUR
        VERFÜGUNG
      </H2>
      <Container>
        <Item>Telefon:</Item>
        <Link href="tel:+49217384984460">+49 2173 84984 460</Link>
        <Item>Fax:</Item>
        <Link href="fax:+49217384984973">+49 2173 84984 973</Link>
        <Item>E-Mail:</Item>
        <Link href="mailto:info-haft@controlexpert.com">
          info-haft@controlexpert.com
        </Link>
      </Container>
      <div>
        <i>- Ihr Mobile Check Service Center</i>
      </div>
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  background-color: ${(p) => transparentize(0.95, p.theme.colors.primary)};
  padding: 7rem 4rem 7rem 0rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
  & > :not(:last-child) {
    padding-bottom: 2rem;
  }
`;

const H2 = styled.h2`
  text-align: center;
  font-family: "UnitCompPro-Ultra W04 Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  padding-bottom: 2rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > :nth-child(2n):not(:last-child) {
    padding-right: 2rem;
  }

  & > :nth-child(2n + 1):not(:last-child) {
    padding-right: 0.5rem;
  }

  @media screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
    & > :nth-child(2n):not(:last-child) {
      padding-right: 0;
    }

    & > :nth-child(2n + 1):not(:last-child) {
      padding-right: 0;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.primary};
  cursor: pointer;
`;

const Item = styled.span`
  text-align: right;
`;
